// #([a-f0-9]{3}){1,2}\b
:root {}

$transparent: transparent;

$primary: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)));
$primary8: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) + 8%)); // #f47502; [4]
$primary05o: hsla(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)), 0.05%);
$primary015o: hsla(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)), 0.15%);
$primary1o: hsla(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)), 0.1%);
$primary2o: hsla(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)), 0.2%);
$primary30o: hsla(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l)), 30%);

$secondary: hsl(var(--color-secondary-h), var(--color-secondary-s), calc(var(--color-secondary-l)));
$secondary8: hsl(var(--color-secondary-h), var(--color-secondary-s), calc(var(--color-secondary-l) + 8%)); // #0484a1; [4]
$secondary20: hsl(var(--color-secondary-h), var(--color-secondary-s), calc(var(--color-secondary-l) + 20%)); // #05b4dd; [2]
$secondary201o: hsla(var(--color-secondary-h), var(--color-secondary-s), calc(var(--color-secondary-l)), .1%); // [1]

$white: #fff;
$white1o: rgba(#fff, .1);
$white8o: rgba(#fff, .8);
$white15o: rgba(#fff, .15);
$white40d: darken($white, 40);

$grey: #383838;
$grey1: lighten($grey, 1); // #3b3b3b; [1]
$grey8: lighten($grey, 8); // #4c4c4c; [4]
$grey46: lighten($grey, 46); // #adadad; [2]
$grey4605o: rgba($grey46, .05); // [1]

$darkGrey: #4a4a4a;
$darkGrey2: lighten($darkGrey, 8); // #5e5e5e; [1]

$danger: #e02e2e;
$danger1o: rgba($danger, .1); // [1]
$danger2: #d3465a;
$danger8: lighten($danger2, 8); // #db6777 [1]
$blue: #2f7dff;
$blue1o: rgba($blue, .1); // [1]

$black: #000;
$black2o: rgba($black, .2);
$black4o: rgba($black, .4);
$black8o: rgba($black, .8);

// Primary
$backgroundPrimary: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l)));

// Primary Lighten
$backgroundPrimary2: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 1.96%)); // #1d1d1d; [11] (1 here)
$backgroundPrimary3: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 3.14%)); // #202020; [8]
$backgroundPrimary4: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 3.92%)); // #222222; [23] (3 here)
$backgroundPrimary5: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5.1%)); // #252525; [7]
$backgroundPrimary6: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5.88%)); // #272727; [10] (1 here)
$backgroundPrimary8: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 7.84%)); // #2c2c2c; [11] (1 here)
$backgroundPrimary10: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 10.2%)); // #323232; [18] (1 here)
$backgroundPrimary12: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 12.16%)); // #373737; [11] (1 here)
$backgroundPrimary14: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 14.12%)); // #3c3c3c; [10]
$backgroundPrimary16: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 16.08%)); // #414141; [2]
$backgroundPrimary20: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 20%)); // #4b4b4b; [8] (1 here)
$backgroundPrimary60: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 60%)); // #b1b1b1; [1] Icon

// Primary Darken
$backgroundPrimary1d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.17%)); // #151515; [21] (4 here)
$backgroundPrimary2d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.96%)); // #131313; [2] (1 here)
$backgroundPrimary6d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 5.88%)); // #090909; [1] (1 here)
$backgroundPrimary8d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 7.84%)); // #040404; [4] (1 here)
$backgroundPrimary18d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 9.41%)); // black; [1] (1 here)
$backgroundPrimary40d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 9.41%)); // black; [1] (1 here)

// Primary Opacity
$backgroundPrimary2o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 0%), 20%); // #181818 [1] rgba(24, 24, 24, .2)
$backgroundPrimary4o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 0%), 40%); // #181818 [2] rgba(24, 24, 24, .4)
$backgroundPrimary27o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2.62%), 70%);
$backgroundPrimary45o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 3.92%), 50%); // #222222 [1] rgba(34, 34, 34, .5)
$backgroundPrimary48o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 3.92%), 80%); // #222222 [1] rgba(34, 34, 34, .8)
$backgroundPrimary26o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 1.96%), 60%); // #1d1d1d [1] rgba(29, 29, 29, .6)
$backgroundPrimary7o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 0%), 70%); // #181818 [1] rgba(24, 24, 24, .7)
$backgroundPrimary87o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 7.84%), 70%); // #2c2c2c [3] rgba(44, 44, 44, .7)
$backgroundPrimary127o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 12.16%), 70%); // #373737 [3] rgba(55, 55, 55, .7)
$backgroundPrimary49o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 3.92%), 90%); // #222222 [2] rgba(34, 34, 34, .9)
$backgroundPrimary69o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5.88%), 90%); // #272727 [2] rgba(39, 39, 39, .9)

$backgroundPrimary1d1o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.17%), 10%); // #151515 [1] rgba(21, 21, 21, .1)
$backgroundPrimary2d2o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.96%), 20%); // #131313 [1] rgba(19, 19, 19, .2)
$backgroundPrimary6d4o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 5.88%), 40%); // #090909 [1] rgba(9, 9, 9, .4)
$backgroundPrimary1d2o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.17%), 20%); // #151515 [3] rgba(21, 21, 21, .2)
$backgroundPrimary1d4o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.17%), 40%); // #151515 [14] (1 here) rgba(21, 21, 21, .4)
$backgroundPrimary1d6o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.17%), 60%); // #151515 [1] rgba(21, 21, 21, .6)
$backgroundPrimary8d2o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 7.84%), 20%); // #040404 [4] rgba(4, 4, 4, .2)
$backgroundPrimary18d05o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 9.41%), 5%); // #000000 [1] rgba(0, 0, 0, .05)
$backgroundPrimary40d3o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 9.41%), 30%); // #000000 [1] rgba(0, 0, 0, .3)



// Secondary
$backgroundSecondary: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 6.28%)); // #282828; [22] (9 here)

// Secondary Lighten
$backgroundSecondary2: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.24%)); // #2d2d2d; [3] (1 here)
$backgroundSecondary4: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 10.59%)); // #333333; [3]

// Secondary Darken
$backgroundSecondary1d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 5.49%)); // #262626; [1]
$backgroundSecondary4d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2.35%)); // #1e1e1e; [3]
$backgroundSecondary5d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 1.57%)); // #1c1c1c; [1]
$backgroundSecondary6d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 0.39%)); // #191919; [1]
$backgroundSecondary8d: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) - 1.57%)); // #141414; [1]
$backgroundSecondary10: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 16.47%)); // #424242; [1]

// Secondary Opacity
$backgroundSecondary215o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.24%), 15%); // #2d2d2d [1] rgba(45, 45, 45, .15)



// Tertiary
$backgroundTertiary: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.63%)); // #2e2e2e; [6] (6 here)
$backgroundTertiary4: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 12.55%)); // #383838; [2]
$backgroundTertiary6: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 14.51%)); // #3d3d3d; [3]
$backgroundTertiary8: hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 16.47%)); // #424242; [3]

// Tertiary Opacity
$backgroundTertiary4o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.63%), 40%); // #2e2e2e [1] rgba(46, 46, 46, .4)
$backgroundTertiary9o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.63%), 90%); // #2e2e2e [1] rgba(46, 46, 46, .9)
$backgroundTertiary94o: hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 8.63%), 94%); // #2e2e2e [1] rgba(46, 46, 46, .94)


$inputBorder: 1px solid rgba($white, .15);
$inputBoxShadow: 0 2px 4px $backgroundPrimary1d4o;
$inputBackgroundColor: $backgroundPrimary4; // #222;

$textGrey: #9b9b9b;
$textGrey12d: darken($textGrey, 12);

$textWhite: #fff;
$textWhite6d: darken($textWhite, 6); // #f0f0f0; [25]
$textWhite8d: darken($textWhite, 8); // #ebebeb; [1]
$textWhite10d: darken($textWhite, 10); // #e6e6e6; [5]
$textWhite20d: darken($textWhite, 20); // #cccccc; [1]
$textWhite25d: darken($textWhite, 25); // #bfbfbf; [1]

$textBlack: #000;

$textPrimary: $primary;
$textPlaceholderColor: rgba($textWhite, .6);
$textPlaceholderColorFocus: rgba($textWhite, .1);

$asideWidth: 300px;
$mainWidth: 720px;

$signUpSideWidth: 180px;
$signUpStepWidth: 640px;

$editModalWidth: 760px;

$borderRadius: 3px;

$iconWhite: #fff;
$playButtonBackground: rgba(#000, .2);

$fontFamily: #{var(--font-family)}, Sans-serif;

:export {
	fontFamily: $fontFamily;

	colorPrimary: $primary;
	colorDanger: $danger;
	colorWhite: $white;
	colorGrey: $grey;
	colorDarkGrey: $darkGrey;

	colorTextWhite: $textWhite;
	colorTextPrimary: $textPrimary;

	colorIconWhite: $iconWhite;

	backgroundPrimary: $backgroundPrimary;
	backgroundPrimary10: $backgroundPrimary10;
	backgroundPrimary20: $backgroundPrimary20;
	backgroundSecondary: $backgroundSecondary;

	borderRadius: $borderRadius;

	inputBorder: $inputBorder;
	inputBoxShadow: $inputBoxShadow;
	inputBackgroundColor: $inputBackgroundColor;
}
