.app--athletes-picker {
	padding: 22px;
	padding-bottom: 160px + 22px;
}

.app--athletes-picker-search {
	margin: 0 0 22px;
	display: flex;
	.search {
		width: 100%;
		margin: 0 0 0 22px;
	}
}

.app--athletes-picker-footer {
	position: fixed;
	width: $mainWidth - 2px;
	left: calc(50% - ((#{$mainWidth - 2px} - #{$asideWidth}) / 2));
	bottom: 0;
	@media screen and (max-width: 1060px) {
		left: 19px;
		right: 19px;
		width: auto;
	}
	@media screen and (max-width: 540px) {
		left: 0;
		right: 0;
	}
}

.app--athletes-picker-footer-title {
	display: flex;
	justify-content: center;
}

.app--athletes-picker-footer-inner {
	padding: 22px;
	background: $backgroundSecondary;
	.app-button {
		width: 100%;
		margin: 18px 0 0;
	}
}

.app--athletes-summary {
	display: flex;
	align-items: center;
	justify-content: space-between;
	p:first-child {
		color: $textWhite6d;
		font-size: 16px;
		font-weight: 600;
	}
	p:last-child {
		color: $primary;
		font-size: 16px;
		font-weight: 600;
	}
}
