body.react-confirm-alert-body-element {
	overflow: hidden;
}

.react-confirm-alert-blur {
	filter: url(#gaussian-blur);
	filter: blur(25px);
	-webkit-filter: blur(25px);
}

.react-confirm-alert-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 200;
	background: $black8o;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: center;
	-ms-align-items: center;
	align-items: center;
	opacity: 0;
	backdrop-filter: blur(25px);
	-webkit-animation: react-confirm-alert-fadeIn .2s forwards;
	-moz-animation: react-confirm-alert-fadeIn .2s forwards;
	-o-animation: react-confirm-alert-fadeIn .2s forwards;
	animation: react-confirm-alert-fadeIn .2s forwards;
	z-index: 1002;
}

.react-confirm-alert-body {
	font-family: $fontFamily;
	width: 400px;
	padding: 20px;
	text-align: left;
	background: $backgroundSecondary;
	color: $textWhite;
	border-radius: $borderRadius;
}

.react-confirm-alert {
	padding: 22px;
}

.react-confirm-alert-svg {
	position: absolute;
	top: 0;
	left: 0;
}

.react-confirm-alert-body > h1 {
	margin-top: 0;
}

.react-confirm-alert-body > h3 {
	margin: 0;
	font-size: 16px;
}

.react-confirm-alert-button-group {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.react-confirm-alert-button-group > button {
	outline: none;
	background: $backgroundSecondary4;
	border: none;
	display: inline-block;
	padding: 6px 18px;
	color: $textWhite10d;
	margin-right: 10px;
	border-radius: $borderRadius;
	font-size: 12px;
	cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-moz-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-o-keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes react-confirm-alert-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media screen and (max-width: 400px + 22px * 2) {
	.react-confirm-alert {
		width: 100%;
		min-width: 320px;
	}
	.react-confirm-alert-body {
		width: 100%;
	}
}
