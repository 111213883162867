.app--athlete-card {
	cursor: pointer;
	border: 1px solid $backgroundPrimary10;
	overflow: hidden;
	position: relative;
	box-shadow: 0 2px 4px $backgroundPrimary1d4o;
	will-change: transform;
	transition: border-color .2s ease;
	&:after {
		z-index: 10;
		content: '';
		display: block;
		padding: 130% 0 0;
		border-radius: $borderRadius;
	}
	&:hover .__photo {
		transform: scale(1.02);
	}
	&.selected {
		border-color: $primary;
	}
	&.featured {
		border: 1.5px solid $primary !important;
		box-shadow: 0 4px 14px $primary30o;
	}
	&,
	.__photo,
	.__inner {
		border-radius: $borderRadius;
	}
	.__photo,
	.__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.__photo {
		z-index: 10;
		background: no-repeat center center/cover $backgroundPrimary6;
		transition: transform .2s ease;
		will-change: transform;
		transform-origin: center bottom;
	}
	.__sport {
		position: absolute;
		top: 12px;
		left: 12px;
		z-index: 12;
		i {
			display: block;
			padding: 6px;
			font-size: 0;
			background: $backgroundPrimary4o;
			border-radius: 4px;
			backdrop-filter: blur(10px);
		}
		svg {
			fill: $iconWhite;
			width: 14px;
			height: auto;
		}
	}
	.__price {
		position: absolute;
		z-index: 12;
		font-size: 0;
		top: 12px;
		left: 26px + 10px + 12px;
		right: 26px + 10px + 12px;
		text-align: center;
		span {
			max-width: 100%;
			color: $textWhite6d;
			display: inline-block;
			background: $backgroundPrimary4o;
			padding: 3px 5px;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			border-radius: $borderRadius;
			backdrop-filter: blur(10px);
		}
	}
	.__legend {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: 20;
		font-size: 0;
		display: block;
		padding: 4px;
		// background: $backgroundPrimary4o;
		background: rgba(#fff, .1);
		border-radius: 4px;
		backdrop-filter: blur(10px);
		svg {
			fill: $secondary8;
			width: 16px;
			height: 18px;
		}
	}
	.__check {
		cursor: pointer;
		position: absolute;
		top: 12px;
		z-index: 12;
		right: 12px;
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $white8o;
		background: $backgroundPrimary;
		box-shadow: 0 2px 4px $backgroundPrimary1d2o;
		transition: background-color .2s ease;
		border-radius: $borderRadius;
		@include backface(26px);
		svg {
			opacity: 0;
			transition: opacity .2s ease;
			pointer-events: none;
		}
		&.checked {
			background: $primary;
			svg {
				opacity: 1;
			}
		}
	}
	.__inner {
		z-index: 11;
		padding: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		@include gradient(
			to bottom,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0) 0%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.013) 8.1%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.049) 15.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.104) 22.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.175) 29%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.259) 35.3%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.352) 41.2%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.45) 47.1%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.55) 52.9%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.648) 58.8%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.741) 64.7%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.825) 71%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.896) 77.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.941) 84.5%,
			hsla(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%), 0.967) 91.9%,
			hsl(var(--color-bg-h), var(--color-bg-s), calc(var(--color-bg-l) + 2%)) 100%
		);
		h4 {
			font-size: 16.5px;
			font-weight: 700;
			text-align: center;
		}
		.athlete-school {
			color: $textGrey;
			text-align: center;
			margin: 4px 0 0;
			font-size: 12.5px;
			font-weight: 600;
			line-height: 1.4;
		}
	}
}
